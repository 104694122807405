<template>
  <div class="container">
    <div class="radioBox">
      <!-- <div class="radio" :class="{'radio_active': type == 1}" @click="radioChange(1)">
        <img :src="type == 1 ? require('@/assets/images/radio1_active.png') : require('@/assets/images/radio1.png')">
        <span>视频</span>
      </div> -->
      <div class="radio" :class="{'radio_active': type == 2}" @click="radioChange(2)">
        <img :src="type == 2 ? require('@/assets/images/radio1_active.png') : require('@/assets/images/radio1.png')">
        <span>图片</span>
      </div>
    </div>
    <div class="uploaderBox">
      <!-- <video class="video" :src="videoUrl" v-if="videoUrl" controls></video> -->
      <div v-if="videoUrl" style="width: 100%; height: 241px;">
        <video
          id="player-container-id"
          style="width: 100%; height: 100%;"
          preload="auto"
          playsinline=""
          webkit-playsinline=""
          x5-playsinline=""
        ></video>
      </div>
      <div class="imgBox" v-show="uploadFileList.length > 0 && videoUrl == ''">
        <img class="imgSingle" :src="item.Url" v-for="(item, index) in uploadFileList" :key="index" @click="imgPreview(index)">
      </div>

      <van-uploader v-show="type ==1 && uploadFileList.length <= 0" :accept="accept" :multiple="multiple" :before-read="beforeRead" :after-read="afterRead">
        <div class="uploader">
          <img class="uploaderIcon" src="@/assets/images/uploaderIcon.png">
          <p class="tips" v-show="type == 1">点击上传视频<br>（建议视频大小不超过5G）</p>
          <!-- <p class="tips" v-show="type == 2">点击上传图片<br>（建议图片大小不超过50M）</p> -->
        </div>
      </van-uploader>

      <div class="uploader" v-show="type == 2 && uploadFileList.length <= 0" @click="chooseImage">
        <img class="uploaderIcon" src="@/assets/images/uploaderIcon.png">
        <!-- <p class="tips" v-show="type == 1">点击上传视频<br>（建议视频大小不超过5G）</p> -->
        <p class="tips" v-show="type == 2">点击上传图片<br>（最多选择9张图片，图片总大小不超过50M）</p>
      </div>

      <!-- <div class="uploader" v-show="type == 2 && uploadFileList.length <= 0">
        <input class="fileInput" ref="file" type="file" accept="image/*" multiple @change="imgChange" />
        <img class="uploaderIcon" src="@/assets/images/uploaderIcon.png">
        <p class="tips" v-show="type == 1">点击上传视频<br>（建议视频大小不超过5G）</p>
        <p class="tips" v-show="type == 2">点击上传图片<br>（建议图片大小不超过50M）</p>
      </div> -->
    </div>
    <div class="inputBox">
      <input type="text" v-model="name" placeholder="给自己的作品取个中意的名字......">
    </div>
    <div class="reupload" @click="reupload">
      <img class="renovate_icon" src="@/assets/images/renovate.png">
      <span>重新上传</span>
    </div>
    <button class="submit" @click="submit">提交</button>
    <div class="declare" @click="checkedDeclare">
      <img :src="isCheckedDeclare ? require('@/assets/images/system-complete@2x.png') : require('@/assets/images/dis_system_complete_icon@2x.png')"/>
      <span class="declareText">同意<span class="highlight" @click="$router.push('/powerAttorney')">《授权同意书》</span>全部条款</span>
    </div>
    <!-- <div class="explain" @click="show = true">规则说明</div> -->
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="wrapper_content">
          <div class="title">
            <span>隐私声明</span>
          </div>
          <div class="ruleInfo">
            <p>我明确知晓强生（上海）医疗器材有限公司（“强生”）为举办爱惜康菁英大赛（即第七届以爱为名心外青年医师手术技能大赛）（“技能大赛”）收集和处理参加技能大赛的参赛选手的个人信息（姓名、所在医院、科室和职称）和其所提交的参赛照片。强生承诺按照强生隐私政策（详见<a href="https://www.jnjmedtech.cn/zh-CN/policies-privacy">https://www.jnjmedtech.cn/zh-CN/policies-privacy</a>）处理和保护我的个人信息。我以勾选方式同意向强生提供以上技能大赛参赛所需要的个人信息和我所提交的参赛照片以及同意《授权同意书》的全部条款和条件。我承诺在我所上传的照片不会包含任何本人和其他自然人的个人信息。
            </p>
          </div>
          <div class="declare1" @click="checkedAgree">
            <img :src="isAgree ? require('@/assets/images/system-complete@2x.png') : require('@/assets/images/dis_system_complete_icon@2x.png')"/>
            <span class="declareText">我已阅读并完全理解和同意<br>上述隐私声明的全部内容</span>
          </div>
          <span class="colseBtn" @click="agree">下一步</span>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TcVod from 'vod-js-sdk-v6'
import { ImagePreview } from "vant";
import wx from "weixin-js-sdk";
import utils from "../../common/utils/utils";
export default {
  name: 'UploadWorks',
  data() {
    return {
      player: null,
      type: 2,
      fileList: [],
      uploadFileList: [],
      accept: 'video/*',
      multiple: false,
      videoUrl: '',
      progress : 0,
      name: '',
      show: false,
      isAgree: false,
      isCheckedDeclare: false
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.getconfig();
  },
  methods: {
    // 初始化播放器
    initVideo() {
      this.player = this.TCPlayer("player-container-id", {
        playbackRates: [0.5, 1, 1.5, 2],
        licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
      }).player;
      this.player.src(this.videoUrl);
    },
    getconfig() {
      const entryLink = utils.getEntryLink();
      this.$axios.get("/Api/Api/web/WechatHelper/GetWechat?backUrl=" + entryLink).then(res => {
        wx.config({
          debug: false,
          appId: res.Data.AppId,
          timestamp: res.Data.TimesTamp,
          nonceStr: res.Data.NonceStr,
          signature: res.Data.She1Str,
          jsApiList: ["chooseImage", "previewImage", "uploadImage"],
        });
      })
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ["chooseImage", "previewImage", "uploadImage"],
          success: function(res) {
            console.log('checkJsApi---',res)
          }
        })
      })
    },
    chooseImage() {
      console.log('chooseImage')
      this.localIdList = [];
      this.serverIdList = [];
      wx.chooseImage({
        count: 9,
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: (res) => {
          this.localIdList = res.localIds;
          console.log('chooseImage localIdList-----', this.localIdList)
          this.syncUpload()
          // console.log('chooseImage res-----', res)
          // let localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
          // console.log('localIds', localIds);
          // localIds.forEach((localId) => {
          //   console.log('forEach-----', localId)
          //   wx.uploadImage({
          //     localId: localId, // 需要上传的图片的本地ID，由 chooseImage 接口获得
          //     isShowProgressTips: 1, // 默认为1，显示进度提示
          //     success: (res1) => {
          //       let serverId = res1.serverId; // 返回图片的服务器端ID
          //       console.log('uploadImage res-----', res1)
          //       console.log('serverId', serverId)
          //       // serverIdList.push(serverId);

          //       // console.log('serverIdList', serverIdList);
          //       this.$axios.post('/Api/api/Web/Works/UploadImg', [serverId]).then(res => {
          //         if (res.RetCode == '10000') {
          //           this.uploadFileList.push(res.Data[0]);
          //         } else {
          //           this.$toast(res.RetMsg);
          //         }
          //       })
          //     }
          //   });
          // })
        }
      });
    },
    syncUpload() {
      if (!this.localIdList.length) {
        this.$axios.post('/Api/api/Web/Works/UploadImg', this.serverIdList).then(res => {
          if (res.RetCode == '10000') {
            this.$toast.clear();
            this.uploadFileList = res.Data;
          } else {
            this.$toast(res.RetMsg);
          }
        })
      } else {
        let localId = this.localIdList.pop();
        wx.uploadImage({
          localId: localId,
          isShowProgressTips: 0,
          success: (res) => {
            let serverId = res.serverId;
            this.serverIdList.push(serverId);
            this.$toast.loading({
              message: '正在上传',
              forbidClick: true,
              duration: 0,
              loadingType: 'spinner',
            });
            this.syncUpload();
          }
        });
      }
    },
    radioChange(type) {
      if(this.type != type) {
        this.videoUrl = '';
        this.fileList = [];
        this.uploadFileList = [];
        this.name = '',
        this.type = type;

        if(type == 1) {
          this.accept = 'video/*';
          this.multiple = false;
          if(this.player) {
            this.player.dispose();
            this.player = null;
          }
        } else {
          this.accept = 'image/*';
          this.multiple = true;
        }
        
      }
    },
    imgChange() {
      let files = this.$refs.file.files;
      if(files.length > 0) {
        this.fileList = files;
        const formData = new FormData();
        Object.keys(this.fileList).map(val => {
          formData.append("file", this.fileList[val]);
        })
        this.uploadImg(formData);
      }
    },
    beforeRead(file) {
      if(file.size > 5 *1024 * 1024 * 1024) {
        this.$toast('视频大小不能超过5G');
        return false;
      } else {
        return true;
      }
    },
    afterRead(file) {
      const formData = new FormData();
      this.fileList = [];
      console.log('afterRead------',file)
      if(file instanceof Array) {
        file.forEach(item => {
          this.fileList.push(item.file);
          formData.append("file", item.file);
        })
      } else {
        this.fileList.push(file.file)
        formData.append("file", file.file);
      }

      if(this.type == 1) {
        this.uploadVideo();
        this.toast = this.$toast.loading({
          message: '已上传' + Math.floor(this.progress * 100) + '%',
          forbidClick: true,
          duration: 0,
          loadingType: 'spinner',
        });
      } else {
        this.uploadImg(formData);
      }
    },
    getSignature() {
      return this.$axios.get('/Api/api/Web/Works/GetTX').then(res => {
        return res;
      })
    },
    uploadVideo() {
      this.tcVod = new TcVod({
        getSignature: this.getSignature
      })

      let mediaFile = this.fileList[0];
      const uploader = this.tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })

      // 视频上传完成时
      uploader.on('media_upload', info => {
        console.log('视频上传完成时------',info)
        this.$toast.clear()
      })
      // 视频上传进度
      uploader.on('media_progress', info => {
        console.log('视频上传进度------', info)
        this.progress = info.percent;
        this.toast.message = `已上传${Math.floor(this.progress * 100)}%`;
      })
      uploader.done().then(doneResult => {
        console.log('doneResult-----', doneResult)
        this.videoUrl = doneResult.video.url;
        this.uploadFileList.push({Name: '', Url: doneResult.video.url});
        this.$nextTick(()=> {
          this.initVideo();
        })
      }).catch(function (err) {
        console.log('err',err);
      })
    },
    uploadImg(formData) {
      this.$toast.loading({
        message: '正在上传',
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
      });
      this.$axios.post('/Api/api/Web/Works/UploadImg', formData).then(res => {
        this.$toast.clear();
        if (res.RetCode == '10000') {
          this.uploadFileList = res.Data;
        } else {
          this.$toast(res.RetMsg);
        }
      })
    },
    imgPreview(index) {
      let imgList = [];
      this.uploadFileList.forEach(item => {
        imgList.push(item.Url);
      })
      ImagePreview({
        images: imgList,
        startPosition: index,
      });
    },
    agree() {
      if(!this.isAgree) {
        this.$toast('请勾选隐私声明！');
        return false;
      }

      this.show = false;
      let data = {
        CuId: this.userInfo.Id,
        Type: this.type,
        Title: this.name,
        lst: this.uploadFileList
      }

      this.$axios.post('/Api/api/Web/Works/Add', data).then(res => {
        this.isAgree = false;
        this.$toast({
          type: 'success',
          message: '提交成功',
          onClose: () => {
            this.$router.go(-1);
          }
        });
      })
    },
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    checkedAgree() {
      this.isAgree = !this.isAgree;
    },
    reupload() {
      if(this.player) {
        this.player.dispose();
        this.player = null;
      }
      this.videoUrl = '';
      this.fileList = [];
      this.uploadFileList = [];
    },
    submit() {      
      if(this.uploadFileList.length <=0) {
        this.$toast('请上传您的作品！');
        return false;
      }

      if(!this.name) {
        this.$toast('请输入您的作品名称！');
        return false;
      }

      if(!this.isCheckedDeclare) {
        this.$toast('请勾选《授权同意书》！');
        return false;
      }
      
      if(!this.isAgree) {
        this.show = true;
      }

      // let data = {
      //   CuId: this.userInfo.Id,
      //   Type: this.type,
      //   Title: this.name,
      //   lst: this.uploadFileList
      // }

      // this.$dialog.confirm({
      //   title: '提示',
      //   message: '确认提交作品',
      // }).then(() => {
      //   this.$axios.post('/Api/api/Web/Works/Add', data).then(res => {
      //     this.$toast({
      //       type: 'success',
      //       message: '提交成功',
      //       onClose: () => {
      //         this.$router.go(-1);
      //       }
      //     });
      //   })
      // }).catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  min-height: 100%;
  background-color: #F9F9F9;
  padding: 30px;
  box-sizing: border-box;
  .radioBox {
    display: flex;
    .radio {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333333;
      margin-right: 50px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
    .radio_active {
      color: #CA001B;
    }
  }
  .uploaderBox {
    margin: 20px auto;
  }
  .video {
    width: 100%;
  }
  .imgBox {
    border-radius: 4px;
    border: 1px solid #DDDDDD;
    padding: 10px;
    background-color: #FFFFFF;
    .imgSingle {
      display: block;
      width: 100%;
    }
    .imgSingle:not(:last-of-type) {
      margin-bottom: 5px;
    }
  }
  .uploader {
    position: relative;
    width: 314px;
    height: 190px;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    text-align: center;
    .uploaderIcon {
      width: 35px;
      margin: 55px auto 40px;
    }
    .tips {
      font-size: 12px;
      color: rgba(51, 51, 51, 0.50);
      line-height: 18px;
    }
    .fileInput {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .inputBox {
    width: 100%;
    height: 30px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    background-color: #FFFFFF;
    input {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      border: none;
      background: transparent;
      font-size: 14px;
    }
    /*webkit 内核浏览器*/
    input::-webkit-input-placeholder{
      font-size: 12px;
      color: #999999;
      text-align: center;
    }
    /*Mozilla Firefox 19+*/
    input::-moz-placeholder{
      font-size: 12px;
      color: #999999;
      text-align: center;
    }
    /*Mozilla Firefox 4 to 18*/
    input:-moz-placeholder{
      font-size: 12px;
      color: #999999;
      text-align: center;
    }
    /*Internet Explorer 10+*/
    input:-ms-input-placeholder{
      font-size: 12px;
      color: #999999;
      text-align: center;
    }
  }
  .reupload {
    width: 80px;
    font-size: 14px;
    font-weight: bold;
    color: #CA001B;
    display: flex;
    align-items: center;
    margin: 25px auto 0;
    .renovate_icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      margin-top: 2px;
    }
  }
  .submit {
    display: block;
    width: 170px;
    height: 50px;
    border: none;
    border-radius: 4px;
    background-color: #CA001B;
    margin: 20px auto 25px;
    color: #FFFFFF;
  }
  .explain {
    width: 50px;
    font-size: 12px;
    color: #CA001B;
    line-height: 20px;
    border-bottom: 1px solid #CA001B;
    margin: 0 auto;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .wrapper_content {
    display: flex;
    flex-direction: column;
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    height: 400px;
    max-height: 400px;
    padding: 20px;
    box-sizing: border-box;
    .title {
      position: relative;
      text-align: center;
      span {
        position: relative;
        z-index: 9;
        display: inline-block;
        color: #333333;
        font-size: 15px;
        font-weight: bold;
        background-color: #FFFFFF;
        padding: 0 15px;
      }
    }
    .title::after {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0px;
      content: "";
      width: 100%;
      height: 1px;
      background-color: rgba(151, 151, 151, 0.5);
    }
    .ruleInfo {
      flex: 1;
      height: 0;
      margin: 20px 0;
      overflow-y: scroll;
      font-size: 12px;
      color: #333333;
      p {
        line-height: 20px;
      }
      a {
        color: #409eff;
        text-decoration: underline;
      }
    }
    .colseBtn {
      display: inline-block;
      width: 50px;
      text-align: center;
      font-size: 14px;
      padding: 8px 15px;
      color: #FFFFFF;
      background-color: #CA001B;
      margin: 12px auto 0;
      border-radius: 4px;
    }
  }
  .declare {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #666666;
    margin-top: 20px;
    img {
      width: 14px;
      height: 14px;
      margin: 0 5px 0 10px;
    }
  }
  .declare1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    color: #666666;
    img {
      display: block;
      width: 14px;
      height: 14px;
      margin: 2px 5px 0 0;
    }
  }
  .highlight {
    color: #CA001B;
  }
}
</style>